import { ProjectStage } from "@/types/general";
import ReactSlider from "react-slider";

interface ProjectStageTimelineProps {
  projectStages: ProjectStage[];
  selectedStages: number[]; // Single point or range
  onChange: (selectedStages: number[]) => void;
}

const ProjectStageTimeline = ({
  projectStages,
  selectedStages,
  onChange,
}: ProjectStageTimelineProps) => {
  // Determine the slider value: single point or range
  let sliderValue = Array.isArray(selectedStages)
    ? selectedStages
    : [selectedStages];

  if (sliderValue.length === 1) {
    sliderValue = [sliderValue[0], sliderValue[0]];
  }

  return (
    <div className="relative overflow-x-auto p-4">
      <ReactSlider
        className="relative h-2 bg-gray-200 rounded"
        thumbClassName="w-4 h-4 bg-blue-500 rounded-full border border-white shadow focus:outline-none focus:ring-2 focus:ring-blue-400"
        trackClassName="bg-blue-300 h-2 rounded"
        value={sliderValue}
        withTracks
        onChange={(val) => {
          const newVal = Array.isArray(val) ? val : [val];
          onChange(newVal);
        }}
        min={0}
        max={projectStages.length - 1}
        step={1}
        pearling
        renderThumb={(props, state) => (
          <div
            {...props}
            key={state.index}
            className="w-4 h-4 bg-blue-500 rounded-full border border-white shadow flex items-center justify-center text-xs text-white transform -translate-y-1/2"
          >
            <span className="hidden sm:block whitespace-nowrap px-2 py-1 bg-blue-500 text-white rounded">
              {projectStages[state.valueNow]?.name}
            </span>
          </div>
        )}
        renderMark={(props) => {
          const markIndex = Number(props.key);
          const stageName = projectStages[markIndex]?.name || "";
          return (
            <span
              // {...props}
              className="absolute text-xs text-gray-700 whitespace-nowrap bg-white px-1 py-0.5 rounded border border-gray-300 shadow transform translate-y-2 -translate-x-1/2"
            >
              {stageName}
            </span>
          );
        }}
      />
    </div>
  );
};

export default ProjectStageTimeline;
