import Layout from "@/components/Layout";
import LoginForm from "@/components/LoginForm";
import AdvisedProjects from "@/components/Pages/AdvisedProjects";
import Advisors from "@/components/Pages/Advisors";
import Confirmation from "@/components/Pages/Confirmation";
import Dashboard from "@/components/Pages/Dashboard";
import Explore from "@/components/Pages/Explore";
import Homepage from "@/components/Pages/Homepage";
import Inbox from "@/components/Pages/Inbox";
import PasswordReset from "@/components/Pages/PasswordReset";
import { Project, Projects } from "@/components/Pages/Projects";
import Profile from "@/components/Pages/Settings/Profile";
import SignupForm from "@/components/SignupForm";
import { Route, Routes } from "react-router-dom";

export default (
  <Routes>
    {/* Public Routes */}
    <Route path="/login" element={<LoginForm />} />
    <Route path="/signup" element={<SignupForm />} />
    <Route path="/users/confirmation" element={<Confirmation />} />
    <Route path="/users/unlock" element={<PasswordReset />} />
    <Route path="/users/password/edit" element={<PasswordReset />} />
    <Route index element={<Homepage />} />

    {/* Protected Routes */}
    <Route path="/" element={<Layout />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/inbox" element={<Inbox />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:projectId" element={<Project />} />
      <Route path="/advisors" element={<Advisors />} />
      <Route path="/advised_projects" element={<AdvisedProjects />} />
      <Route path="/settings/profile" element={<Profile />} />
    </Route>
  </Routes>
);
