import { apiInstance } from "@/api/axiosConfig";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import DropzoneUploader from "@/components/ui/DropzoneUploader";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import ProjectStageTimeline from "@/components/ui/ProjectStageTimeline";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import {
  ExpertiseArea,
  Industry,
  ProjectStage,
  Skill,
  Technology,
} from "@/types/general";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  bio: z.string().optional(),
  years_of_experience: z.coerce.number().min(0).optional(),
  available_for_advising: z.boolean().optional(),
  linkedin_url: z.string().url().optional().or(z.literal("")),
  github_url: z.string().url().optional().or(z.literal("")),
  twitter_handle: z.string().optional(),
  website: z.string().url().optional().or(z.literal("")),
  city: z.string().optional(),
  country: z.string().optional(),
  profile_picture: z.string().optional(),
  skill_ids: z.array(z.string().uuid()).default([]),
  technology_ids: z.array(z.string().uuid()).default([]),
  expertise_area_ids: z.array(z.string().uuid()).default([]),
  industry_ids: z.array(z.string().uuid()).default([]),
  preferred_project_stage_ids: z.array(z.string().uuid()).default([]),
});

type ProfileFormValues = z.infer<typeof formSchema>;

const Profile = () => {
  const { toast } = useToast();
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      bio: "",
      years_of_experience: 0,
      available_for_advising: true,
      linkedin_url: "",
      github_url: "",
      twitter_handle: "",
      website: "",
      city: "",
      country: "",
      profile_picture: "",
      skill_ids: [],
      technology_ids: [],
      expertise_area_ids: [],
      industry_ids: [],
      preferred_project_stage_ids: [],
    },
  });

  const { handleSubmit, control, watch, setValue } = form;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  // Example states to hold fetched options
  const [skills, setSkills] = useState<Skill[]>([]);
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [expertiseAreas, setExpertiseAreas] = useState<ExpertiseArea[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [projectStages, setProjectStages] = useState<ProjectStage[]>([]);
  const [loading, setLoading] = useState(true);

  const currentProfilePicture = watch("profile_picture");

  useMemo(() => {
    Promise.all([
      apiInstance.get("/skills").then((res) => res.data),
      apiInstance.get("/technologies").then((res) => res.data),
      apiInstance.get("/expertise_areas").then((res) => res.data),
      apiInstance.get("/industries").then((res) => res.data),
      apiInstance.get("/project_stages").then((res) => res.data),
    ])
      .then(
        ([
          skillData,
          technologyData,
          expertiseAreaData,
          industryData,
          projectStageData,
        ]) => {
          setSkills(skillData);
          setTechnologies(technologyData);
          setExpertiseAreas(expertiseAreaData);
          setIndustries(industryData);
          setProjectStages(
            projectStageData.map((stage: ProjectStage) => ({
              ...stage,
              selected: false, // Add a selection state
            }))
          );
        }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    apiInstance
      .get("/profile")
      .then((response) => {
        const profileData = response.data;

        setValue("first_name", profileData.firstName || "");
        setValue("last_name", profileData.lastName || "");
        setValue("bio", profileData.bio || "");
        setValue("years_of_experience", profileData.yearsOfExperience || 0);
        setValue(
          "available_for_advising",
          profileData.availableForAdvising || false
        );
        setValue("linkedin_url", profileData.linkedinUrl || "");
        setValue("github_url", profileData.githubUrl || "");
        setValue("twitter_handle", profileData.twitterHandle || "");
        setValue("website", profileData.website || "");
        setValue("city", profileData.city || "");
        setValue("country", profileData.country || "");
        setValue("profile_picture", profileData.profilePicture || "");
        setValue(
          "skill_ids",
          profileData.skills?.map((skill: any) => skill.id) || []
        );
        setValue(
          "technology_ids",
          profileData.technologies?.map((technology: any) => technology.id) ||
            []
        );
        setValue(
          "expertise_area_ids",
          profileData.expertiseAreas?.map((ea: any) => ea.id) || []
        );
        setValue(
          "industry_ids",
          profileData.industries?.map((i: any) => i.id) || []
        );
        setValue(
          "preferred_project_stage_ids",
          profileData.preferredProjectStages?.map((pps: any) => pps.id) || []
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, [setValue]);

  useEffect(() => {
    // If a file is selected, create a preview URL
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setPreviewURL(url);

      // Cleanup: Revoke the object URL when the component unmounts or file changes
      return () => URL.revokeObjectURL(url);
    } else {
      setPreviewURL(null);
    }
  }, [selectedFile]);

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();

    // Append all non-file fields
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        [...new Set(value)].forEach((val) => {
          formData.append(`user[${key}][]`, val);
        });
      } else if (key !== "profile_picture") {
        formData.append(`user[${key}]`, String(value ?? ""));
      }
    }

    if (selectedFile) {
      formData.append("user[profile_picture]", selectedFile, selectedFile.name);
    }

    apiInstance
      .put("/profile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("User updated:", response.data);
        toast({
          title: "Profile updated",
          description: "Your profile has been successfully updated.",
        });
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        toast({
          title: "Error updating profile",
          description: "An error occurred while updating your profile.",
        });
      });
  });

  useEffect(() => {
    const handleSaveShortcut = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "s") {
        e.preventDefault();
        onSubmit();
      }
    };

    window.addEventListener("keydown", handleSaveShortcut);
    return () => {
      window.removeEventListener("keydown", handleSaveShortcut);
    };
  }, [onSubmit]);

  const renderCheckBoxGroup = (
    fieldName: keyof ProfileFormValues,
    options: { id: string; name: string }[]
  ) => {
    const selectedValues = watch(fieldName);
    const currentValues = Array.isArray(selectedValues) ? selectedValues : [];
    const current = new Set(currentValues);

    const toggleValue = (id: string) => {
      const updated = new Set(currentValues);
      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }
      setValue(fieldName, Array.from(updated) as any);
    };

    return (
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {options.map((option) => (
          <div key={option.id} className="flex items-center space-x-2">
            <Checkbox
              checked={current.has(option.id)}
              onCheckedChange={() => toggleValue(option.id)}
              id={option.id}
            />
            <Label
              htmlFor={option.id}
              className="text-sm font-light leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
            >
              {option.name}
            </Label>
          </div>
        ))}
      </div>
    );
  };

  const groupedTechnologies = technologies.reduce<Record<string, Technology[]>>(
    (acc, tech) => {
      const category = tech.category || "Other";
      if (!acc[category]) acc[category] = [];
      acc[category].push(tech);
      return acc;
    },
    {}
  );

  const groupedSkills = skills.reduce<Record<string, Skill[]>>((acc, skill) => {
    const category = skill.category || "Other";
    if (!acc[category]) acc[category] = [];
    acc[category].push(skill);
    return acc;
  }, {});

  const groupedIndustries = industries.reduce<Record<string, Industry[]>>(
    (acc, industry) => {
      const category = industry.category || "Other";
      if (!acc[category]) acc[category] = [];
      acc[category].push(industry);
      return acc;
    },
    {}
  );

  const groupedExpertiseAreas = expertiseAreas.reduce<
    Record<string, ExpertiseArea[]>
  >((acc, expertiseArea) => {
    const category = expertiseArea.category || "Other";
    if (!acc[category]) acc[category] = [];
    acc[category].push(expertiseArea);
    return acc;
  }, {});

  const handleProjectStageChange = (selectedIndices: number[]) => {
    setProjectStages((prevStages) =>
      prevStages.map((stage, index) => ({
        ...stage,
        selected: selectedIndices.includes(index), // Mark selected stages
      }))
    );

    // Update form field with selected IDs
    const selectedIds = selectedIndices.map(
      (index) => projectStages[index]?.id
    );
    setValue("preferred_project_stage_ids", selectedIds);
  };

  const selectedStages = projectStages
    .map((stage, index) =>
      form.getValues("preferred_project_stage_ids")?.includes(stage.id)
        ? index
        : null
    )
    .filter((index) => index !== null) as number[];

  return (
    <div className="w-5/6 md:w-3/4 mx-auto py-4">
      <Card className="p-6">
        <h1 className="text-xl font-semibold mb-6">Profile</h1>
        {loading ? (
          <div className="flex flex-col space-y-3">
            <Skeleton className="h-[125px] w-[250px] rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={onSubmit} className="space-y-8">
              {/* Personal Information */}
              <h2 className="text-xl font-medium mb-4">Personal Information</h2>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input placeholder="John" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Doe" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="col-span-full md:col-span-1">
                  <DropzoneUploader
                    onFileSelect={setSelectedFile}
                    previewURL={previewURL ?? currentProfilePicture ?? null}
                  />
                </div>

                <FormField
                  control={control}
                  name="bio"
                  render={({ field }) => (
                    <FormItem className="col-span-full md:col-span-1">
                      <FormLabel>Bio</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="A short bio..."
                          {...field}
                          className="min-h-[80%]"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Professional Details */}
              <h2 className="text-xl font-medium mt-8 mb-4">
                Professional Details
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={control}
                  name="years_of_experience"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Years of Experience</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="available_for_advising"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2 mt-6">
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="mb-0">
                        Available for Advising
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input placeholder="San Francisco" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Country</FormLabel>
                      <FormControl>
                        <Input placeholder="USA" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Social Links */}
              <h2 className="text-xl font-medium mt-8 mb-4">Social Links</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <FormField
                  control={control}
                  name="linkedin_url"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>LinkedIn URL</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="https://linkedin.com/yourprofile"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="github_url"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>GitHub URL</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="https://github.com/yourhandle"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="twitter_handle"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Twitter Handle</FormLabel>
                      <FormControl>
                        <Input placeholder="@yourhandle" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="website"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Website</FormLabel>
                      <FormControl>
                        <Input placeholder="https://yoursite.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Multi-select fields */}
              <h2 className="text-xl font-medium mt-8 mb-4">
                Expertise & Interests
              </h2>
              <FormItem>
                <FormLabel className="text-lg">Skills</FormLabel>
                {Object.keys(groupedSkills).map((category) => (
                  <section key={category}>
                    <h5 className="py-2">{category}</h5>
                    {renderCheckBoxGroup("skill_ids", groupedSkills[category])}
                  </section>
                ))}
              </FormItem>

              <FormItem>
                <FormLabel className="text-lg">Technologies</FormLabel>
                {Object.keys(groupedTechnologies).map((category) => (
                  <section key={category}>
                    <h5 className="py-2">{category}</h5>
                    {renderCheckBoxGroup(
                      "technology_ids",
                      groupedTechnologies[category]
                    )}
                  </section>
                ))}
              </FormItem>

              <FormItem>
                <FormLabel className="text-lg">Areas of Expertise</FormLabel>
                {Object.keys(groupedExpertiseAreas).map((category) => (
                  <section key={category}>
                    <h5 className="py-2">{category}</h5>
                    {renderCheckBoxGroup(
                      "expertise_area_ids",
                      groupedExpertiseAreas[category]
                    )}
                  </section>
                ))}
              </FormItem>

              <FormItem>
                <FormLabel className="text-lg">Industries</FormLabel>
                {Object.keys(groupedIndustries).map((category) => (
                  <section key={category}>
                    <h5 className="py-2">{category}</h5>
                    {renderCheckBoxGroup(
                      "industry_ids",
                      groupedIndustries[category]
                    )}
                  </section>
                ))}
              </FormItem>

              <FormItem className="col-span-4 overflow-x-visible">
                <FormLabel className="text-lg">
                  Preferred Project Stages
                </FormLabel>
                {projectStages.length > 0 && (
                  <ProjectStageTimeline
                    projectStages={projectStages}
                    selectedStages={selectedStages}
                    onChange={handleProjectStageChange}
                  />
                )}
              </FormItem>

              <Button type="submit">Save Profile</Button>
            </form>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default Profile;
