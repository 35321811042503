import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

type DropzoneUploaderProps = {
  onFileSelect: (file: File | null) => void;
  previewURL?: string | null; // Accept a previewURL prop
};

const DropzoneUploader: React.FC<DropzoneUploaderProps> = ({
  onFileSelect,
  previewURL,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        onFileSelect(acceptedFiles[0]);
      } else {
        onFileSelect(null);
      }
    },
    [onFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dashed border-gray-300 p-4 w-fit rounded-md text-center cursor-pointer hover:border-blue-500 transition-colors relative"
    >
      <input {...getInputProps()} />
      {(() => {
        if (previewURL) {
          return (
            <div className="flex flex-col items-center justify-center space-y-2">
              <img
                src={previewURL}
                alt="Preview"
                className="w-32 h-32 object-cover rounded-md mx-auto"
              />
              <p className="text-gray-600">
                Drag & drop or click to replace the image
              </p>
            </div>
          );
        } else if (isDragActive) {
          return <p className="text-blue-600">Drop the file here ...</p>;
        } else {
          return (
            <div className="text-gray-500 w-64 h-40 flex items-center">
              Drag 'n' drop a file here, or click to select one
            </div>
          );
        }
      })()}
    </div>
  );
};

export default DropzoneUploader;
