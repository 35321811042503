import React from "react";
import ReactDOM from "react-dom/client";
import "../..//assets/stylesheets/application.tailwind.css"; // Adjust the path as needed

import App from "../components/App";
import { BrowserRouter as Router } from "react-router-dom";
import { CableProvider } from "@/context/cable";

// Find a root element to mount the React app
document.addEventListener("DOMContentLoaded", () => {
  // Ensure the `react-root` exists in the DOM
  let rootElement = document.getElementById("react-root");
  if (!rootElement) {
    rootElement = document.createElement("div");
    rootElement.id = "react-root";
    document.body.appendChild(rootElement);
  }

  // Create the React root and render the application
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <CableProvider>
        <Router>
          <App />
        </Router>
      </CableProvider>
    </React.StrictMode>
  );
});
