import React, { ReactNode } from "react";
import ActionCable from "actioncable";

interface CableContextType {
  cable: ActionCable.Cable;
}

const CableContext = React.createContext<CableContextType | null>(null);

function CableProvider({ children }: Readonly<{ children: ReactNode }>) {
  const actionCableUrl =
    process.env.NODE_ENV === "production"
      ? "wss://<your-deployed-app-domain>.com/cable"
      : "ws://localhost:3000/cable";

  const cable = ActionCable.createConsumer(actionCableUrl);

  const cableValue = React.useMemo(() => ({ cable }), [cable]);

  return (
    <CableContext.Provider value={cableValue}>{children}</CableContext.Provider>
  );
}

export { CableContext, CableProvider };
