// Layout.js
import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { Toaster } from "@/components/ui/toaster";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <SidebarProvider defaultOpen={true}>
      <AppSidebar />
      <div className="w-full h-screen flex flex-col">
        <SidebarTrigger />
        <main className="flex-1 flex items-center justify-center">
          <Outlet />
        </main>
        <Toaster />
      </div>
    </SidebarProvider>
  );
}

export default Layout;
