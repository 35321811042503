// SignupForm.jsx
import React, { useState } from "react";
import logo from "@/assets/images/logo.png";
import { authInstance } from "@/api/axiosConfig";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";

function SignupForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      // debugger;
      const response = await authInstance.post("/users", {
        user: {
          email,
          password,
        },
      });
      const token = response.headers["authorization"]?.split(" ")[1];
      if (token) {
        localStorage.setItem("token", token);
        navigate("/dashboard");
      } else {
        console.error("Token not found in response headers");
      }
    } catch (error) {
      console.error("Login error", error);
    }
  };

  return (
    <div className="flex flex-row items-center justify-center h-screen">
      <Card className="w-fit min-w-96">
        <CardHeader>
          <div className="flex flex-col gap-2 items-center justify-center">
            <CardTitle>Advisory</CardTitle>
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                width={128}
                height={128}
                className="mb-4"
              />
            </Link>
          </div>
          <CardTitle>Sign Up for Advisory</CardTitle>
          <CardDescription>
            Connect with your next best resource in minutes
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder="Email"
            />
            <Separator className="mt-2" />
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              placeholder="Password"
            />
          </form>
        </CardContent>
        <CardFooter className="flex flex-col align-center justify-center gap-2">
          <Button
            type="submit"
            onClick={handleSubmit}
            onTouchStart={handleSubmit}
          >
            Get Started Now
          </Button>
          <Button
            variant="link"
            type="submit"
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default SignupForm;
