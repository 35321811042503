import logo from "@/assets/images/logo.png";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <h1 className="text-2xl font-bold mb-4">Advisory</h1>
      <img src={logo} alt="Logo" width={128} height={128} className="mb-4" />
      <p>Because it&apos;s dangerous to go alone.</p>
      <div className="flex flex-col gap-2">
        <Button className="mt-4" onClick={() => navigate("/signup")}>
          Get Started Now
        </Button>
        <Button
          variant="secondary"
          className="mt-4"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
