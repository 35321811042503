import { Project } from "@/types/general";
import { useState } from "react";
import TinderCard from "react-tinder-card";
import { Button } from "./button";
import { UUID } from "crypto";

interface ProjectCardProps {
  project: Project;
  removeCard: (projectId: UUID) => void;
}

const ProjectCard = ({ project, removeCard }: ProjectCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <TinderCard
      className="swipe-card"
      swipeRequirementType="position"
      swipeThreshold={200}
      preventSwipe={["up", "down"]}
      onSwipe={(dir) => {
        if (dir === "left" || dir === "right") {
          removeCard(project.id);
        }
      }}
    >
      <div
        className="relative w-72 h-96 cursor-pointer transition-transform duration-500"
        style={{
          perspective: 1000,
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "none",
        }}
      >
        {/* Front Side */}
        <div
          className="absolute inset-0 bg-white flex flex-col justify-between items-center border border-gray-200 rounded-lg shadow-lg p-4"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(0deg)",
          }}
        >
          {/* Logo */}
          {project.logoUrl && (
            <img
              src={project.logoUrl}
              alt={`${project.title} Logo`}
              className="h-20 w-20 object-cover rounded-full mb-4"
            />
          )}
          <div className="text-left">
            <h3 className="text-xl font-bold text-center">{project.title}</h3>
            <p className="mt-2 text-gray-500">Industries: </p>
            <p className="mt-1 text-gray-700">
              {project.industries.map((industry) => industry.name).join(", ")}
            </p>
            <p className="mt-2 text-gray-500">Stage:</p>
            <p className="mt-1 text-gray-700 line-clamp-2">
              {project.projectStages.map((stage) => stage.name).join(", ")}
            </p>
          </div>
          <Button
            onClick={toggleFlip}
            onTouchStart={toggleFlip}
            className="mt-4"
          >
            More Info
          </Button>
        </div>

        {/* Back Side */}
        <div
          className="absolute inset-0 bg-blue-500 flex flex-col justify-between items-center border border-gray-200 rounded-lg shadow-lg p-4 overflow-hidden"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <div className="text-center overflow-y-auto">
            <h3 className="text-xl font-bold text-white">About the Project</h3>
            <p className="mt-2 text-white">{project.description}</p>

            <p className="mt-4 text-white font-bold">Goals</p>
            <ul className="list-disc list-inside text-white">
              {project.goals?.map((goal) => (
                <li key={goal}>{goal}</li>
              ))}
            </ul>

            <p className="mt-4 text-white font-bold">Pitch Decks</p>
            {project.pitchDecks?.length ? (
              <ul className="list-none text-center px-4 list-inside text-white">
                {project.pitchDecks.map((deck, index) => (
                  <li key={index}>
                    <a
                      href={deck}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white underline"
                    >
                      View Deck {index + 1}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-white">No pitch decks available.</p>
            )}

            <p className="mt-4 text-white font-bold">
              Founders: {project.founders.length}, Advisors:{" "}
              {project.advisors.length}
            </p>
          </div>

          <Button
            onClick={toggleFlip}
            onTouchStart={toggleFlip}
            className="mt-4"
          >
            Back
          </Button>
        </div>
      </div>
    </TinderCard>
  );
};

export default ProjectCard;
