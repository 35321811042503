import { useState } from "react";
// import { Avatar, ScrollArea, Separator, Input, Card } from "@/components/ui";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils"; // If you use the cn utility from shadcn

interface Message {
  id: string;
  sender: string;
  content: string;
  timestamp: Date;
}

interface Conversation {
  id: string;
  name: string; // The other person's name or a title of the conversation
  messages: Message[];
}

export default function Inbox() {
  const conversations = [
    {
      id: "1",
      name: "John Doe",
      messages: [
        {
          id: "1",
          sender: "John Doe",
          content: "Hello!",
          timestamp: new Date(),
        },
        {
          id: "2",
          sender: "Jane Doe",
          content: "Hi, how are you?",
          timestamp: new Date(),
        },
      ],
    },
    {
      id: "2",
      name: "Jane Doe",
      messages: [
        {
          id: "1",
          sender: "Jane Doe",
          content: "Hey!",
          timestamp: new Date(),
        },
        {
          id: "2",
          sender: "John Doe",
          content: "Hi, how are you?",
          timestamp: new Date(),
        },
      ],
    },
  ];
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(conversations.length > 0 ? conversations[0].id : null);

  const selectedConversation = conversations.find(
    (c) => c.id === selectedConversationId
  );

  return (
    <div className="flex h-full w-full">
      {/* LEFT SIDEBAR */}
      <aside className="w-64 border-r border-gray-200 flex flex-col">
        <div className="p-4">
          <h1 className="text-lg font-bold">Inbox</h1>
        </div>
        <Separator />
        <div className="p-2">
          <Input placeholder="Search conversations..." />
        </div>
        <Separator />
        <ScrollArea className="flex-1">
          {conversations.map((conversation) => (
            <button
              key={conversation.id}
              onClick={() => setSelectedConversationId(conversation.id)}
              className={cn(
                "w-full text-left p-3 hover:bg-gray-100 cursor-pointer",
                selectedConversationId === conversation.id &&
                  "bg-gray-100 font-medium"
              )}
            >
              <div className="flex items-center space-x-2">
                <Avatar>
                  {/* You can put an <AvatarImage/> and <AvatarFallback/> here */}
                  {/* <AvatarImage src="..." alt={conversation.name}/> */}
                  <AvatarFallback>{conversation.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <span>{conversation.name}</span>
              </div>
            </button>
          ))}
        </ScrollArea>
      </aside>

      {/* MAIN CONTENT AREA */}
      <main className="flex-1 flex flex-col">
        {selectedConversation ? (
          <>
            {/* Conversation Header */}
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-bold">{selectedConversation.name}</h2>
            </div>

            {/* Messages List */}
            <ScrollArea className="flex-1 p-4 space-y-4">
              {selectedConversation.messages.map((message) => (
                <Card key={message.id} className="p-2">
                  <div className="flex items-center space-x-2">
                    <Avatar>
                      <AvatarFallback>
                        {message.sender.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <div className="font-medium">{message.sender}</div>
                      <div className="text-sm text-gray-600">
                        {message.content}
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </ScrollArea>

            {/* Message Composer */}
            <div className="p-4 border-t border-gray-200 flex space-x-2">
              <Input placeholder="Type your message..." className="flex-1" />
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
                Send
              </button>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center text-gray-500">
            Select a conversation to view messages
          </div>
        )}
      </main>
    </div>
  );
}
