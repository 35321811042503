import { apiInstance } from "@/api/axiosConfig";
import AdvisoreCard from "@/components/ui/AdvisorCard";
import ProjectCard from "@/components/ui/ProjectCard";
import { Match } from "@/types/general";
import { UUID } from "crypto";
import { motion } from "motion/react";
import { useEffect, useState } from "react";

export default function Explore() {
  const [role, setRole] = useState<string | null>(null);
  const [matches, setMatches] = useState<Match[]>([]);

  const handleRoleSelection = (selectedRole: any) => {
    setRole(selectedRole);
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await apiInstance.get("/matches", {
          params: { role, limit: 10 },
        });

        const { matches: responseMatches } = response.data;
        setMatches(responseMatches);
      } catch (error: any) {
        console.error("Error fetching cards: ", error);
      }
    };

    if (role) {
      fetchCards();
    }
  }, [role]);

  // Inside the Explore component
  const removeCard = (cardId: UUID) => {
    setMatches((prevCards) => prevCards.filter((card) => card.id !== cardId));
  };

  return (
    <div className="w-full flex flex-col items-center justify-center space-y-4">
      {!role ? (
        <div className="flex flex-col space-y-4 items-center">
          <h2 className="text-xl font-semibold">Explore Opportunities</h2>
          <div className="flex space-x-4">
            <motion.button
              initial={{ opacity: 1 }}
              animate={role === "Advisor" ? { opacity: 0 } : {}}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleRoleSelection("Founder")}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              I'm a Founder
            </motion.button>
            <motion.button
              initial={{ opacity: 1 }}
              animate={role === "Founder" ? { opacity: 0 } : {}}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleRoleSelection("Advisor")}
              className="px-4 py-2 bg-green-500 text-white rounded-md"
            >
              I'm an Advisor
            </motion.button>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col items-center space-y-4">
          <h2 className="text-xl font-semibold">
            {role === "Founder" ? "Explore Advisors" : "Explore Projects"}
          </h2>
          <div className="relative w-80 h-[400px]">
            {matches.map((match, index) => (
              <motion.div
                key={match.id} // Use card.id as the key
                className="absolute inset-y-0 inset-x-3"
                style={{
                  zIndex: matches.length - index,
                  transform: `translateY(${index * 10}px)`,
                }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {match.type === "User" ? (
                  <AdvisoreCard user={match} removeCard={removeCard} />
                ) : (
                  <ProjectCard project={match} removeCard={removeCard} />
                )}
              </motion.div>
            ))}
          </div>
          <div>
            <button
              onClick={() => {
                setMatches([]);
                setRole(null);
              }}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
