import { apiInstance } from "@/api/axiosConfig";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Project } from "@/types/general";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { AvatarFallback } from "@radix-ui/react-avatar";

export default function Projects() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiInstance.get("/projects").then((response) => {
      const { projects: projectsResponse } = response.data;
      setProjects(projectsResponse);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Skeleton />
  ) : (
    <Card className="w-5/6 mx-auto">
      <CardHeader>
        <h1 className="text-2xl font-semibold">Your Projects</h1>
      </CardHeader>
      <CardContent className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {projects.map((project) => (
          <Link
            to={`/projects/${project.id}`}
            key={project.id}
            className="grow"
          >
            <Card className="h-full flex flex-col p-4">
              <CardContent className="flex flex-col items-center gap-4 justify-between h-full">
                <h1 className="text-xl font-semibold">{project.title}</h1>
                <Avatar className="mx-auto">
                  <AvatarImage src={project.logoUrl} alt={project.title} />
                  <AvatarFallback>{project.title.charAt(0)}</AvatarFallback>
                </Avatar>
              </CardContent>
            </Card>
          </Link>
        ))}
        {projects.length % 3 === 0 && (
          <Card className="!border-none !shadow-none"></Card>
        )}
        <Card className="h-full flex flex-col items-center justify-center">
          <CardContent>
            <Link to="/projects/new">
              <h1 className="text-2xl font-semibold text-center">
                Create a new project
              </h1>
            </Link>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
