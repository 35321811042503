// app/frontend/api/axiosConfig.ts
import axios from 'axios';

// Use environment variables injected at build time
const apiBaseURL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000/api/v1';
const authBaseURL = import.meta.env.VITE_AUTH_BASE_URL || 'http://localhost:3000/';

const apiInstance = axios.create({
  baseURL: apiBaseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

const authInstance = axios.create({
  baseURL: authBaseURL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Or use a more secure storage method
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 403) {
        // Clear any saved token
        localStorage.removeItem('token');

        // Redirect to login page
        window.location.href = '/login'; // Adjust the path as necessary
      }
    }
    return Promise.reject(error);
  }
);

export { apiInstance, authInstance };
