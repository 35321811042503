import { apiInstance } from "@/api/axiosConfig";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Project as ProjectType } from "@/types/general";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Project() {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<ProjectType | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiInstance.get(`/projects/${projectId}`).then((response) => {
      const { project: projectResponse } = response.data;

      setProject(projectResponse);
      setLoading(false);
    });
  }, [projectId]);

  return loading ? (
    <Skeleton />
  ) : (
    <div>
      <Card>
        <CardHeader>
          <h1 className="text-2xl font-semibold">{project?.title}</h1>
        </CardHeader>
        <CardContent>
          <p>{project?.description}</p>
          <p>Advisors</p>
          <div className="flex flex-wrap gap-4">
            {project?.advisors.map((advisor) => (
              <div key={advisor.id}>
                <p>
                  {advisor.firstName} {advisor.lastName}
                </p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
