import { User } from "@/types/general";
import { useState } from "react";
import TinderCard from "react-tinder-card";
import { Button } from "./button";
import { UUID } from "crypto";

interface AdvisorCardProps {
  user: User;
  removeCard: (userId: UUID) => void; // Accept removeCard function
}

const AdvisorCard = ({ user, removeCard }: AdvisorCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <TinderCard
      className="swipe-card"
      swipeRequirementType="position"
      swipeThreshold={200}
      preventSwipe={["up", "down"]}
      onSwipe={(dir) => {
        if (dir === "left" || dir === "right") {
          removeCard(user.id);
        }
      }}
    >
      <div
        className="relative w-72 h-96 cursor-pointer transition-transform duration-500"
        style={{
          perspective: 1000,
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "none",
        }}
      >
        {/* Front Side */}
        <div
          className="absolute inset-0 bg-white flex flex-col justify-between items-center border border-gray-200 rounded-lg shadow-lg"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(0deg)",
          }}
        >
          <div className="w-full">
            {/* Profile Picture */}
            {user.profilePicture ? (
              <img
                src={user.profilePicture}
                alt={`${user.firstName} ${user.lastName}`}
                className="w-full h-40 object-cover rounded-t-lg"
              />
            ) : (
              <div className="w-full h-40 bg-gray-300 flex justify-center items-center mx-auto rounded-t-lg">
                <span className="text-sm text-gray-500">No Image</span>
              </div>
            )}
            <div className="text-center p-4">
              <h3 className="text-xl font-bold">
                {user.firstName} {user.lastName}
              </h3>
              {/* Expertise Areas */}
              <p className="mt-2 text-gray-500">Expertise Areas</p>
              <ul className="list-none list-inside text-sm text-left w-full text-gray-700">
                {user.expertiseAreas.slice(0, 3).map((area) => (
                  <li
                    key={area.id}
                    className="text-ellipsis whitespace-nowrap overflow-hidden block"
                  >
                    {area.name}
                  </li>
                ))}
              </ul>

              {/* Location */}
              <p className="mt-2 text-gray-500">{user.city}</p>
              <Button
                onClick={toggleFlip}
                onTouchStart={toggleFlip}
                className="mt-4"
              >
                More Info
              </Button>
            </div>
          </div>
        </div>

        {/* Back Side */}
        <div
          className="absolute inset-0 bg-blue-500 flex flex-col justify-between items-center border border-gray-200 rounded-lg shadow-lg p-4 overflow-hidden"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <div className="text-center overflow-y-auto">
            <h3 className="text-xl font-bold text-white">
              About {user.firstName}
            </h3>
            <p className="mt-2 text-white">{user.bio}</p>
            <p className="mt-4 text-white font-bold">
              Experience: {user.yearsOfExperience} years
            </p>

            <p className="mt-4 text-white font-bold">Industries</p>
            <ul className="list-disc list-inside text-left text-white">
              {user.industries.map((industry) => (
                <li key={industry.id}>{industry.name}</li>
              ))}
            </ul>

            <p className="mt-4 text-white font-bold">Skills</p>
            <ul className="list-disc list-inside text-left whitespace-nowrap text-white">
              {user.skills.map((skill) => (
                <li key={skill.id}>{skill.name}</li>
              ))}
            </ul>
          </div>

          <Button
            onClick={toggleFlip}
            onTouchStart={toggleFlip}
            className="mt-4"
          >
            Back
          </Button>
        </div>
      </div>
    </TinderCard>
  );
};

export default AdvisorCard;
