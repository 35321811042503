import React from "react";
import logo from "@/assets/images/logo.png";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      Your Dashboard
    </div>
  );
}
